import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import PATHS from './routesMap'
import Auth from '../views/auth/Auth.vue'

const routes = [
  {
    name: 'Auth',
    path: PATHS.AUTH,
    component: Auth,
  },
  {
    path: PATHS.HOME,
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: PATHS.GROUPS_DETAIL,
    name: 'Groups',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/groups/Groups.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: PATHS.REPORTS,
    name: 'Reports',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/reports/ReportsHome.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: PATHS.REPORTS_GENERAL,
    name: 'ReportsGeneral',
    component: () =>
      import(
        /* webpackChunkName: "home" */ '../views/reports/ReportsGeneral.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: PATHS.REPORTS_MONTHLY,
    name: 'ReportsMonthly',
    component: () =>
      import(
        /* webpackChunkName: "home" */ '../views/reports/ReportsUnityAndMonth.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: PATHS.UNAUTHORIZED,
    name: 'Unauthorized',
    component: () =>
      import(
        /* webpackChunkName: "home" */ '../views/unauthorized/Unauthorized.vue'
      ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: () =>
      import(
        /* webpackChunkName: "notFound" */ '../views/notFound/NotFound.vue'
      ),
  },
]

const router = createRouter({
  base: process.env.VUE_APP_BASE_URL,
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from) => {
  if (store.getters.isLoggedInAdmQuiz && to.name === 'Auth') {
    return { name: 'Home' }
  }
  if (to.meta.requiresAuth) {
    if (
      !store.getters.isLoggedInAdmQuiz &&
      !localStorage.getItem('tokenAdmQuiz')
    ) {
      return { name: 'Auth' }
    }
  }
})

router.afterEach(async (to, from) => {
  if (!store.getters.isLoggedInAdmQuiz && to.meta.requiresAuth) {
    await store.dispatch('logout')
    router.push(PATHS.AUTH)
  }
})
export default router
