import api from '@/services/api'

export default {
  actions: {
    async saveQuestion({ commit }, data) {
      try {
        const response = await api.post(
          `/quiz/groups/${data.uuid}/questions`,
          data.question,
        )
        return response
      } catch (error) {
        throw error
      }
    },
    async updateQuestion({ commit }, data) {
      try {
        const response = await api.put(
          `/quiz/groups/${data.uuid}/questions/${data.question.questionId}`,
          data.question,
        )
        return response
      } catch (error) {
        throw error
      }
    },
    async getQuestions({ commit }, data) {
      try {
        const response = await api.get(`/quiz/groups/${data.uuid}/questions`)
        return response && response.data ? response.data : []
      } catch (error) {
        throw error
      }
    },
    async getQuestion({ commit }, data) {
      try {
        const response = await api.get(
          `/quiz/groups/${data.uuid}/questions/${data.id}`,
        )
        return response && response.data ? response.data : []
      } catch (error) {
        throw error
      }
    },
    async deleteQuestion({ commit }, data) {
      try {
        return await api.delete(
          `/quiz/groups/${data.uuid}/questions/${data.questionId}`,
        )
      } catch (error) {
        throw error
      }
    },
  },
}
