import api from '@/services/api'

export default {
  actions: {
    async saveGroup({ commit }, group) {
      try {
        const response = await api.post('/quiz/groups', group)
        return response
      } catch (error) {
        throw error
      }
    },
    async saveChoiceOfGuys({ commit }, group) {
      try {
        const response = await api.post('/quiz/choice-of-guys', group)
        return response
      } catch (error) {
        throw error
      }
    },

    async getGroup({ commit }, group) {
      try {
        const response = await api.get(`/quiz/groups/${group.uuid}`)
        return response && response.data ? response.data : []
      } catch (error) {
        throw error
      }
    },
    async updateGroup({ commit }, group) {
      try {
        const response = await api.put(`/quiz/groups/${group.uuid}`, group)
        return response
      } catch (error) {
        throw error
      }
    },
    async getGroups({ commit }, group) {
      try {
        const response = await api.get('/quiz/groups')
        return response && response.data ? response.data : []
      } catch (error) {
        throw error
      }
    },
    async getUnities({ commit }, uuid) {
      try {
        const response = await api.get(`/quiz/groups/${uuid}/unities`)
        return response && response.data ? response.data : []
      } catch (error) {
        throw error
      }
    },
    async updateUnities({ commit }, data) {
      try {
        const response = await api.put(
          `/quiz/groups/${data.uuid}/unities`,
          data.unities,
        )
        return response
      } catch (error) {
        throw error
      }
    },
  },
}
