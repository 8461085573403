import api from '@/services/api'

export default {
  actions: {
    async getGeneralRanking({ commit }) {
      try {
        const response = await api.get(`/quiz/reports/general`)
        return response && response.data ? response.data : []
      } catch (error) {
        throw error
      }
    },
    async getMontlyRanking({ commit }, data) {
      try {
        const query = new URLSearchParams(data)
        const response = await api.get(
          `/quiz/reports/monthly?${query.toString()}`,
        )
        return response && response.data ? response.data : []
      } catch (error) {
        throw error
      }
    },
  },
}
