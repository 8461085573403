<template>
  <div class="navbar navbar-dark bg-primary navbar-expand-lg navbar-static">
    <div class="container-fluid ps-1">
      <div class="navbar-brand">
        <router-link to="/home" class="d-inline-flex align-items-center">
          <img src="@/assets/img/grey.png" class="h-32px" alt="Logo do Senar" />
          <span class="ms-2 text-white fs-lg"
            >MESTRE DO VALOR SN - ADMINISTRATIVO</span
          >
        </router-link>
      </div>
      <div class="d-xl-none">
        <button
          type="button"
          class="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-navigation"
          aria-expanded="true"
        >
          <i class="ph-list" />
        </button>
      </div>
      <div class="navbar-collapse collapse" id="navbar-navigation">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item nav-item-dropdown-lg dropdown ms-lg-2">
            <a
              href="#"
              class="navbar-nav-link align-items-center rounded-pill p-1"
              data-bs-toggle="dropdown"
            >
              <div class="status-indicator-container">
                <img
                  :src="`https://sistemasgruposn.com.br/sistemas/documentos/portal/colaboradores/fotos/${user.localPhoto}`"
                  class="w-32px h-32px rounded-pill"
                  alt=""
                />
                <span class="status-indicator bg-success"></span>
              </div>
              <span class="d-none d-lg-inline-block mx-lg-2">{{
                user.name
              }}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <router-link to="logout" class="dropdown-item">
                <i class="ph-sign-out me-2"></i>
                Logout
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import PATHS from '@/router/routesMap'

export default {
  name: 'Navbar',

  components: {},

  data() {
    return {
      showAccountModal: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
  },
}
</script>
